import { useEffect, useState } from "react";
import "../CSS/home.css";
import { Container } from "react-bootstrap";
import AppNavbars from "./Navbar2";
import Videobanner from "./videobanner";
import Producthomepage from "./producthomepage";
import Servicehomepage from "./servicehome";
import Contacthomepage from "./contacthome";
import Abouthomepage from "./abouthomepage";

const Home = () => {
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    const bodyNode = document.body || document.getElementsByTagName("body")[0];
    const pages = document.querySelectorAll(".page");
    const navPoint = document.querySelectorAll(".nav_point");

    bodyNode.style.right = bodyNode.clientWidth - bodyNode.offsetWidth + "px";

    function showPage() {
      pages.forEach((el, i) => {
        if (i === pageIndex) {
          el.classList.add("active");
        } else {
          el.classList.remove("active");
        }
      });

      const active = document.querySelector(".active");
      active && active.scrollIntoView({ behavior: "smooth" });

      colorNav();
    }

    function colorNav() {
      navPoint.forEach((el, i) => {
        el.classList.remove("active_point");
        if (pageIndex === i) {
          el.classList.add("active_point");
        }
      });
    }

    function handleWheel(event) {
      if (event.deltaY < 0 && pageIndex > 0) {
        if (
          event.target.closest('div[id="home"]') ||
          event.target.closest('section[id="home"]')
        ) {
          setPageIndex(0);
        } else if (
          event.target.closest('div[id="about"]') ||
          event.target.closest('section[id="about"]')
        ) {
          setPageIndex(0);
        } else if (
          event.target.closest('div[id="product"]') ||
          event.target.closest('section[id="product"]')
        ) {
          setPageIndex(1);
        } else if (
          event.target.closest('div[id="service"]') ||
          event.target.closest('section[id="service"]')
        ) {
          setPageIndex(2);
        } else if (
          event.target.closest('div[id="content"]') ||
          event.target.closest('section[id="content"]')
        ) {
          setPageIndex(3);
        }
      } else if (event.deltaY > 0 && pageIndex < pages.length - 1) {
        if (
          event.target.closest('div[id="home"]') ||
          event.target.closest('section[id="home"]')
        ) {
          setPageIndex(1);
        } else if (
          event.target.closest('div[id="about"]') ||
          event.target.closest('section[id="about"]')
        ) {
          setPageIndex(2);
        } else if (
          event.target.closest('div[id="product"]') ||
          event.target.closest('section[id="product"]')
        ) {
          setPageIndex(3);
        } else if (
          event.target.closest('div[id="service"]') ||
          event.target.closest('section[id="service"]')
        ) {
          setPageIndex(4);
        } else if (
          event.target.closest('div[id="content"]') ||
          event.target.closest('section[id="content"]')
        ) {
          setPageIndex(4);
        }
      }
    }

    bodyNode.addEventListener("wheel", handleWheel);

    navPoint.forEach((el, i, p) => {
      el.onclick = (e) => {
        setPageIndex(i);
        showPage();
      };
    });

    showPage();

    return () => {
      bodyNode.removeEventListener("wheel", handleWheel);
    };
  }, [pageIndex]);

  return (
    <div style={{overflow : "hidden"}}>
      <Container fluid className="p-0">
        <Videobanner />
        <div className="main">
          <AppNavbars pageIndex={pageIndex} />
          <section id="home" className="page first_page"></section>
          <section id="about" className="page second_page">
            <Abouthomepage />
          </section>
          <section id="product" className="page third_page">
            <Producthomepage />
          </section>
          <section id="service" className="page fourth_page">
            <Servicehomepage />
          </section>
          <section id="content" className="page fifth_page">
            <Contacthomepage />
          </section>
          <section className="nav">
            <div className="nav_point"></div>
            <div className="nav_point"></div>
            <div className="nav_point"></div>
            <div className="nav_point"></div>
            <div className="nav_point"></div>
          </section>
        </div>
      </Container>
    </div>
  );
};

export default Home;
