import React from "react";
import "../CSS/services3.css";
import { Col, Container, Row } from "react-bootstrap";
import FormPage from "./form";
import AppNavbar from "../Component/Navbars";
import servicepic3 from "../img/about51.jpg";
import Documents from "../img/document.png";
import Manual from "../img/manualbook.png";
import Auditing from "../img/auditing.png";
import Footer from "../Component/footer";
import { Helmet } from "react-helmet";

const Services3Page = () => {
  return (
    <>
     <Helmet>
        <title>Data standardization</title>
        <meta
          name="description"
          content="Galax Biotech, as a leading provider of data standardization services tailored specifically for clinical trials and research projects. At Galax Biotech, we providing you with top-notch data standardization services, including CDISC compliance and personalized solutions to meet your unique requirements."
        />
        <meta
          name="keywords"
          content="Data, standardization, services, galaxbiotech"
        />
      </Helmet>
      <AppNavbar />
      <div className="capable_container">
        <div className="capability_pages">
          <Container> 
            <Row className="contains gx-5">
              <Col md={6}>
                <div className="services_rainvent">
                  <p>
                    <b>Data standardization</b>
                  </p>
                  <p>
                    Galax Biotech, as a leading provider of data standardization
                    services tailored specifically for clinical trials and
                    research projects. At Galax Biotech, we providing you with
                    top-notch data standardization services, including CDISC
                    compliance and personalized solutions to meet your unique
                    requirements.
                  </p>
                  <p>
                    We are offering comprehensive range of data standardization
                    services.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="products1_rainvent">
                  <img src={servicepic3} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="capabless_container">
        <div className="capability_pages">
          <Container>
            <div className="widgets3_of_services3">
              <Row>
                <Col md={4}>
                  <div className="service3__wizards3">
                    <img src={Documents} alt="" />
                    <h6>Data Harmonization</h6>
                    <p>
                      Our team of experts specializes in harmonizing data from
                      various sources, ensuring consistency and accuracy
                      throughout your datasets. By standardizing your data, you
                      can eliminate inconsistencies and improve the overall
                      quality of your clinical research.
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="service3__wizards3">
                    <img src={Manual} alt="" />
                    <h6>CDISC Standardization (SDTM and ADaM)</h6>
                    <p>
                      We understand the importance of adhering to CDISC
                      standards in clinical trials. Our experienced team is
                      well-versed in SDTM (Study Data Tabulation Model) and ADaM
                      (Analysis Data Model) standards, enabling us to convert
                      your raw data into CDISC-compliant formats effortlessly.
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="service3__wizards3">
                    <img src={Auditing} alt="" />
                    <h6>CSDRG and ADRG</h6>
                    <p>
                      Our knowledge extends beyond SDTM and ADaM standards. We
                      are proficient in Clinical Study Data Reviewer’s Guide
                      (CSDRG) as well as Analysis Dataset Reviewer’s Guide
                      (ADRG), enabling us to provide comprehensive support
                      throughout the entire data review process.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <div className="capableity_container">
        <div className="capability_pages">
          <Container>
            <div className="widgets3b_of_services3">
              <Row style={{ margin: "0 200px" }}>
                <Col md={6}>
                  <div className="service3b__wizards3">
                    <img src={Documents} alt="" />
                    <h6>Annotated Case Report Forms (aCRF)</h6>
                    <p>
                      With our expertise in electronic data capture systems, we
                      can design and implement annotated Case Report Forms
                      (aCRF) tailored to your specific study requirements. This
                      ensures efficient data collection while maintaining
                      compliance with industry regulations.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="service3b__wizards3">
                    <img src={Manual} alt="" />
                    <h6>Pinnacle and Galax Define Validation</h6>
                    <p>
                      As pioneers in the field, we have extensive experience
                      working with Pinnacle 21 and Galax Define validation tools
                      for regulatory submissions. Our team will ensure that all
                      datasets are validated accurately, minimizing any
                      potential errors or discrepancies.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <div className="capables1_containers1">
        <div className="capability_pages">
          <Container className="contains gx-5">
            <Row>
              <Col md={6}>
                <div className="services_rainvent">
                  <b>Request for services</b>
                  <p>
                    We would be delighted to discuss your specific requirements
                    further and provide a tailored solution that aligns with
                    your project goals. Please feel free to reach out to us at
                    any time via email or phone.
                  </p>
                  <p>
                    Please don't hesitate to reach out if you have any questions
                    or would like more information about any of our services. We
                    are here to help you succeed.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="services_rainvents1">
                  <FormPage />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default Services3Page;
