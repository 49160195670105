import config from "../config/config.json";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const invitedDataServices = {
  // Invited Data
  invitedClientRegistration,
  invitedUserRegistration,
  getInvitationData,
  getCountries,
  getStates,
  getCities,
};

// Registered Company By invitation
async function invitedClientRegistration(userData) {
  let headerData = {
    "content-type": "application/json",
  };
  const response = await fetch(
    `${config.API_URL}invitedClientRegistration`,
    headersWithAuth("POST", userData, headerData)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Registered User By invitation
async function invitedUserRegistration(userData) {
  let headerData = {
    "content-type": "application/json",
  };
  const response = await fetch(
    `${config.API_URL}invitedUserRegistration`,
    headersWithAuth("POST", userData, headerData)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get Inivitation Details
async function getInvitationData(token) {
  const response = await fetch(
    `${config.API_URL}getInvitationData/${token}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Country
async function getCountries() {
  const response = await fetch(
    `${config.API_URL}getCountries`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get State
async function getStates(id) {
  const response = await fetch(
    `${config.API_URL}getStates/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get City
async function getCities(id) {
  const response = await fetch(
    `${config.API_URL}getCities/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}