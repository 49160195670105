import React from "react";
import "../CSS/about.css";
import { Col, Container, Row } from "react-bootstrap";
import aboutimg from "../img/eightth1.jpg";
// import Sign from "../img/sign.png";
import AppNavbar from "../Component/Navbars";
import AboutImg from "../img/about3.jpg";
import AboutImg2 from "../img/about4.jpg";
import AboutImg3 from "../img/about21.jpg";
import ClinicT from "../img/about51.jpg";
import AiPower from "../img/aipower1.jpg";
import Partner from "../img/partner1.jpg";
import Ourjourney from "../img/ourjourney1.jpeg";
import Complience from "../img/compli1.jpeg";
import Footer from "../Component/footer";
import { Helmet } from "react-helmet";
const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="Pioneering the Future of Clinical Trials with AI-Driven CTMS Solutions. Welcome to GALAX BIOTECH CTMS Solutions, where innovation meets clinical excellence."
        />
        <meta name="keywords" content="About, galaxbiotech" />
      </Helmet>
      <AppNavbar />
      <div className="about_container">
        <div className="about_page">
          <Container>
            <Row className="contains gx-5">
              <Col md={4}>
                <div className="about_img">
                  <img src={aboutimg} alt="" />
                </div>
              </Col>
              <Col md={8}>
                <div>
                  <p className="About_us">About Us</p>
                  <p className="pionner">
                    Pioneering the Future of Clinical Trials with
                    <b> AI-Driven CTMS</b> Solutions.
                  </p>
                  <p className="welcome">
                    Welcome to GALAX BIOTECH CTMS Solutions, where innovation
                    meets clinical excellence. We are a leading provider of
                    <b> cutting-edge Clinical Trial Management Systems </b>
                    (CTMS), dedicated to revolutionizing the landscape of
                    clinical research.
                  </p>
                  <p className="welcome">
                    Our commitment lies in offering advanced, <b>AI-powered </b>
                    tools and comprehensive <b>EDC </b>software solutions to
                    facilitate seamless <b>end-to-end</b> clinical trial
                    management.
                  </p>
                  {/* <div className="sign_img">
                    <img src={Sign} alt="" />
                    <span className="found"> (Founder)</span>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="abouts_container">
        <div className="about_page">
          <Container className="contains">
            <Row>
              <Col md={6} sm={12}>
                <div className="about_rainvents">
                  <p className="vision m-0">Our Vision</p>
                  <h1 className="empo">Empowering Research, Enhancing Lives</h1>
                  <div className="our_vision">
                    <img src={AboutImg} alt="" />
                    <p>
                      Our vision is to <b>empower</b> researchers and
                      <b> healthcare </b>
                      professionals with state-of-the-art technology, enabling
                      them to conduct clinical trials more <b>efficiently</b> ,
                      <b> accurately</b> and <b>ethically</b>.
                    </p>
                  </div>
                  <div className="strive">
                    <img src={AboutImg2} alt="" />
                    <p>
                      We strive to enhance <b>patient outcomes</b> and
                      <b> advance medical </b>
                      knowledge, ultimately contributing to healthier lives
                      worldwide.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="abouts_years">
                  <img src={AboutImg3} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="aboutts_container">
        <div className="about_page">
          <Container className="contains">
            <Row>
              <Col md={6} sm={12}>
                <div className="about_rainvents">
                  <img src={ClinicT} alt="" />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="abouts_years">
                  <b>End-to-End EDC Software for Clinical Trials</b>
                  <Row className="heart_part">
                    <Col md={1} className="text-end p-0 pt-1">
                      <i className="fa-solid fa-circle-check"></i>
                    </Col>
                    <Col md={11}>
                      <p>
                        Our Electronic Data Capture (EDC) software is the
                        cornerstone of our CTMS offerings. It provides an
                        integrated platform for seamless data collection,
                        management, and analysis. With features like real-time
                        data entry, validation checks, and secure data storage,
                        our EDC system ensures the integrity and reliability of
                        clinical trial data.
                      </p>
                    </Col>
                  </Row>
                  <Row className="heart_part">
                    <Col md={1} className="text-end p-0 pt-1">
                      <i className="fa-solid fa-circle-check"></i>
                    </Col>
                    <Col md={11}>
                      <p>
                        It supports various phases of clinical research, from
                        early-phase trials to post-marketing studies, catering
                        to the diverse needs of the clinical research community.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="about_containers1">
        <div className="about_page">
          <Container className="contains">
            <Row>
              <Col md={6} sm={12}>
                <div className="about_rainvent">
                  <img src={AiPower} alt="" />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="about_years">
                  <b>Innovative AI-Powered Tools</b>
                  <Row className="heart_part">
                    <Col md={1} className="text-end p-0 pt-1">
                      <i className="fa-solid fa-circle-check"></i>
                    </Col>
                    <Col md={11}>
                      <p className="technology_typeabout">
                        At the heart of our CTMS solutions lies our innovative
                        AI technology.
                      </p>
                    </Col>
                  </Row>
                  <Row className="heart_part">
                    <Col md={1} className="text-end p-0 pt-1">
                      <i className="fa-solid fa-circle-check"></i>
                    </Col>
                    <Col md={11}>
                      <p className="technology_typeabout">
                        Leveraging artificial intelligence, we offer predictive
                        analytics, automated data management, and advanced
                        reporting capabilities.
                      </p>
                    </Col>
                  </Row>
                  <Row className="heart_part">
                    <Col md={1} className="text-end p-0  pt-1">
                      <i className="fa-solid fa-circle-check"></i>
                    </Col>
                    <Col md={11}>
                      <p className="technology_typeabout">
                        Our AI tools are designed to streamline the complex
                        processes of clinical trials, from patient recruitment
                        to data analysis, ensuring accuracy and compliance with
                        regulatory standards.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="about_page">
        <Container className="contains">
          <Row>
            <Col xl={4} lg={4} md={6} sm={12} className="col-12 mb-4">
              <div className="compliences">
                <img className="comp_img" src={Complience} alt="" />
                <div className="partners_time">
                  <b>Collaboration and Compliance</b>
                  <p>
                    Collaboration is key in clinical research, and our CTMS
                    solutions are built to facilitate effective collaboration
                    among researchers, sponsors, CROs (Contract Research
                    Organizations), and regulatory bodies. We prioritize
                    compliance with global regulatory standards, including FDA
                    21 CFR Part 11 and GDPR, ensuring that our solutions meet
                    the highest standards of data security and privacy.
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={6} sm={12} className="col-12 mb-4">
              <div className="compliences">
                <img className="comp_img" src={Ourjourney} alt="" />
                <div className="partners_time">
                  <b>Our Journey and Expertise</b>
                  <p>
                    Founded by a team of experts in clinical research and
                    technology, CTMS Solutions has been at the forefront of
                    innovation in clinical trial management. Our expertise spans
                    across various therapeutic areas, and we have successfully
                    powered numerous clinical trials globally, making
                    significant contributions to medical research and patient
                    care.
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={6} sm={12} className="col-12 mb-4">
              <div className="compliences">
                <img src={Partner} alt="" />
                <div className="partners_time">
                  <b>Partnering for a Healthier Tomorrow</b>
                  <p>
                    At CTMS Solutions, we are more than just a technology
                    provider; we are your partners in clinical research. We are
                    committed to supporting our clients in their quest to
                    discover new treatments and improve healthcare outcomes.
                    Join us in our mission to transform the future of clinical
                    trials, one innovation at a time.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default AboutPage;
