import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AppNavbar from "../Component/Navbars";
import Footer from "../Component/footer";
import { Helmet } from "react-helmet";
const CustomerSupport = () => {
  return (
    <>
     <Helmet>
        <title>Customer support Policy</title>
        <meta
          name="description"
          content=" The Customer Support Policy is established to ensure that
          every customer receives timely, proficient, and courteous
          assistance."
        />
        <meta name="keywords" content="customer, support, policy, galaxbiotech" />
      </Helmet>
      <AppNavbar />
      <div className="customerbanerSection">
        <div className="banertextgroup banneroverlay">
          <h1>Customer Support Policy</h1>
        </div>
      </div>
      <Container>
        <Row className="Privacy-12">
          <Col>
            <h1 className="custom-title mb-4">Customer Support Policy</h1>
            <h2>Purpose</h2>
            <ul>
              <li>
                <p>
                  The Customer Support Policy is established to ensure that
                  every customer receives timely, proficient, and courteous
                  assistance.
                </p>
              </li>
            </ul>
            <h2>Scope</h2>
            <ul>
              <li>
                <p>
                  This policy pertains to all interactions involving customer
                  support, encompassing phone calls, emails, live chat sessions,
                  and in-person meetings.
                </p>
              </li>
            </ul>
            <h2>Definitions</h2>
            <ul>
              <li>
                <p>
                  Customer: Anyone reaching out to our company for any purpose.
                </p>
              </li>
              <li>
                <p>
                  Customer Service Representative: An employee engaging with
                  customers.
                </p>
              </li>
              <li>
                <p>
                  Manager: An employee supervising customer service
                  representatives.
                </p>
              </li>
              <li>
                <p>The Company: Our organization.</p>
              </li>
              <li>
                <p>
                  Customer Service Training Manual: A guide instructing customer
                  service representatives about company policies.
                </p>
              </li>
            </ul>
            <h2>Standards</h2>
            <p>
              Our customer service representatives will adhere to these
              standards during interactions with customers:
            </p>
            <ul>
              <li>
                <p>
                  Mastery of this policy as outlined in the customer service
                  training manual is expected at all times.
                </p>
              </li>
              <li>
                <p>
                  Courteous and professional conduct while interacting with
                  customers.
                </p>
              </li>
              <li>
                <p>Prompt and effective resolution of issues.</p>
              </li>
              <li>
                <p>
                  Clear, succinct communication while attentively listening to
                  customer input.
                </p>
              </li>
              <li>
                <p>
                  Managers will oversee adherence to this policy and investigate
                  any related complaints.
                </p>
              </li>
            </ul>
            <h2>Policy</h2>
            <p>
              Our objective is to offer exceptional customer service to all
              clients, ensuring their ongoing satisfaction through consistent
              high-quality support.
            </p>
            <p>
              Although challenges may arise, we commit to promptly and
              efficiently addressing customer concerns. To achieve this, the
              following policy for customer interactions has been implemented:
            </p>
            <ul>
              <li>
                <p>Emails will receive responses within 24 hours.</p>
              </li>
              <li>
                <p>Live chat sessions will be answered within 5 minutes.</p>
              </li>
              <li>
                <p>
                  In-person inquiries will be attended to within 25 seconds.
                </p>
              </li>
              <li>
                <p>
                  Customer service representatives will maintain a courteous and
                  professional demeanor.
                </p>
              </li>
              <li>
                <p>Rapid resolution of issues will be pursued.</p>
              </li>
              <li>
                <p>
                  If a representative is unable to resolve an issue, escalation
                  to a manager is necessary.
                </p>
              </li>
            </ul>
            <h2>Summary</h2>
            <ul>
              <li>
                <p>
                  This policy is subject to modifications without prior notice.
                </p>
              </li>
              <li>
                <p>Last Revision Date: 10 July 2023</p>
              </li>
            </ul>
            <p>
              Thank you for reviewing our Customer Service Policy. We anticipate
              our customer service representatives will display politeness,
              professionalism, and helpfulness in their interactions with you.
              As a representative, your utmost efforts are expected to meet the
              standards outlined in this policy. Managers are responsible for
              ensuring compliance and addressing escalated issues from customers
              or representatives.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </>
  );
};
export default CustomerSupport;
