import React, { useState } from "react";
import AppNavbar from "../Component/Navbars";
import Footer from "../Component/footer";
import Complience from "../img/blogdetails.png";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

function BlogPage() {
  const [viewType, setViewType] = useState("grid");
  return (
    <div>
      <Helmet>
        <title>Blog</title>
        <meta
          name="description"
          content="From comprehensive Clinical Data Services to advanced Biostatistics and Statistical Programming, coupled with meticulous Data Standardization, we ensure precision, reliability, and innovation in healthcare analytics"
        />
        <meta name="keywords" content="Blog, galaxbiotech" />
      </Helmet>
      <AppNavbar />
      <div className="main_serve" >
        <Container className="contains">
          <div className="d-flex justify-content-between pb-3 icon_sets2">
            <h3>Latest Blog</h3>
            {viewType === "list" ? (
              <i
                className="fa-solid fa-grip"
                onClick={() => setViewType("grid")}
              ></i>
            ) : (
              <i
                className="fa-solid fa-list"
                onClick={() => setViewType("list")}
              ></i>
            )}
          </div>
          {viewType === "grid" ? (
            <Row className="mb-4">
              <Col xl={4} lg={4} md={6} sm={12} className="col-12 mb-4">
                <div className="mainServices1">
                  <img className="comp_img" src={Complience} alt="" />
                  <div className="partners_maintime">
                    <div className="date_category">
                      <p className="mb-1">02/03/2024 | 01:32</p>
                      <p className="mb-1">Category: Analytics</p>
                    </div>
                    <p className="top_heading"><b>Maximizing Efficiency and Accuracy: The Impact of Statistical Software with Audit Trail and Version Control</b></p>
                    <p>
                    Introduction: Understanding the Power of Statistical Software in Data Analysis...
                    </p>
                    <div className="read_moreB">
                      <a href={"/maximizing-efficiency-and-accuracy"}>
                        <button>Read More</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col xl={4} lg={4} md={6} sm={12} className="col-12 mb-4">
                <div className="mainServices1">
                  <img className="comp_img" src={Ourjourney} alt="" />
                  <div className="partners_maintime">
                    <div className="date_category">
                      <p className="mb-1">02/03/2024 | 01:32</p>
                      <p className="mb-1">
                        Category: Biostatistics & Programming
                      </p>
                    </div>
                    <b>Biostatistics & Programming</b>
                    <p>
                      We are committed to providing reliable statistical support
                      that meets the highest standards of quality and regulatory
                      compliance. With our expertise, you can confidently
                      navigate the complex statistical...
                    </p>
                    <div className="read_moreB">
                      <a href={"/maximizing-efficiency-and-accuracy"}>
                        <button>Read More</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="col-12 mb-4">
                <div className="mainServices1">
                  <img src={Partner} alt="" />
                  <div className="partners_maintime">
                    <div className="date_category">
                      <p className="mb-1">02/03/2024 | 01:32</p>
                      <p className="mb-1">Category: Data Standardization</p>
                    </div>
                    <b>Data standardization</b>
                    <p>
                      Galax Biotech, as a leading provider of data
                      standardization services tailored specifically for
                      clinical trials and research projects. At Galax Biotech,
                      we providing you with top-notch data standardization
                      services including...
                    </p>
                    <div className="read_moreB">
                      <a href={"/maximizing-efficiency-and-accuracy"}>
                        <button>Read More</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="col-12 mb-4">
                <div className="mainServices1">
                  <img className="comp_img" src={Complience} alt="" />
                  <div className="partners_maintime">
                    <div className="date_category">
                      <p className="mb-1">02/03/2024 | 01:32</p>
                      <p className="mb-1">Category: Clinical Data Management</p>
                    </div>
                    <b>Clinical Data Management</b>
                    <p>
                      At Galax Biotech, we understand the critical nature of
                      data management in the success of any clinical trial. Our
                      team of experienced professionals is dedicated to
                      providing you with top-notch services that will
                      streamline...
                    </p>
                    <div className="read_moreB">
                      <a href={"/maximizing-efficiency-and-accuracy"}>
                        <button>Read More</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="col-12 mb-4">
                <div className="mainServices1">
                  <img className="comp_img" src={Ourjourney} alt="" />
                  <div className="partners_maintime">
                    <div className="date_category">
                      <p className="mb-1">02/03/2024 | 01:32</p>
                      <p className="mb-1">
                        Category: Biostatistics & Programming
                      </p>
                    </div>
                    <b>Biostatistics & Programming</b>
                    <p>
                      We are committed to providing reliable statistical support
                      that meets the highest standards of quality and regulatory
                      compliance. With our expertise, you can confidently
                      navigate the complex statistical...
                    </p>
                    <div className="read_moreB">
                      <a href={"/maximizing-efficiency-and-accuracy"}>
                        <button>Read More</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="col-12 mb-4">
                <div className="mainServices1">
                  <img src={Partner} alt="" />
                  <div className="partners_maintime">
                    <div className="date_category">
                      <p className="mb-1">02/03/2024 | 01:32</p>
                      <p className="mb-1">Category: Data Standardization</p>
                    </div>
                    <b>Data standardization</b>
                    <p>
                      Galax Biotech, as a leading provider of data
                      standardization services tailored specifically for
                      clinical trials and research projects. At Galax Biotech,
                      we providing you with top-notch data standardization
                      services including...
                    </p>
                    <div className="read_moreB">
                      <a href={"/maximizing-efficiency-and-accuracy"}>
                        <button>Read More</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="col-12 mb-4">
                <div className="mainServices1">
                  <img className="comp_img" src={Complience} alt="" />
                  <div className="partners_maintime">
                    <div className="date_category">
                      <p className="mb-1">02/03/2024 | 01:32</p>
                      <p className="mb-1">Category: Clinical Data Management</p>
                    </div>
                    <b>Clinical Data Management </b>
                    <p>
                      At Galax Biotech, we understand the critical nature of
                      data management in the success of any clinical trial. Our
                      team of experienced professionals is dedicated to
                      providing you with top-notch services that will
                      streamline...
                    </p>
                    <div className="read_moreB">
                      <a href={"/maximizing-efficiency-and-accuracy"}>
                        <button>Read More</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="col-12 mb-4">
                <div className="mainServices1">
                  <img className="comp_img" src={Ourjourney} alt="" />
                  <div className="partners_maintime">
                    <div className="date_category">
                      <p className="mb-1">02/03/2024 | 01:32</p>
                      <p className="mb-1">
                        Category: Biostatistics & Programming
                      </p>
                    </div>
                    <b>Biostatistics & Programming</b>
                    <p>
                      We are committed to providing reliable statistical support
                      that meets the highest standards of quality and regulatory
                      compliance. With our expertise, you can confidently
                      navigate the complex statistical...
                    </p>
                    <div className="read_moreB">
                      <a href={"/maximizing-efficiency-and-accuracy"}>
                        <button>Read More</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="col-12 mb-4">
                <div className="mainServices1">
                  <img src={Partner} alt="" />
                  <div className="partners_maintime">
                    <div className="date_category">
                      <p className="mb-1">02/03/2024 | 01:32</p>
                      <p className="mb-1">Category: Data standardization</p>
                    </div>
                    <b>Data standardization</b>
                    <p>
                      Galax Biotech, as a leading provider of data
                      standardization services tailored specifically for
                      clinical trials and research projects. At Galax Biotech,
                      we providing you with top-notch data standardization
                      services including...
                    </p>
                    <div className="read_moreB">
                      <a href={"/maximizing-efficiency-and-accuracy"}>
                        <button>Read More</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          ) : (
            <div>
              <Col className="mb-3">
                <div className="mainServices1">
                  <div className="blog_date_data">
                    <div>
                      <img src={Complience} alt="" />
                    </div>
                    <div className="partners_maintime">
                      <div className="date_category">
                        <p className="mb-1">02/03/2024 | 01:32</p>
                        <p className="mb-1">
                          Category: Analytics
                        </p>
                      </div>
                      <b>Maximizing Efficiency and Accuracy: The Impact of Statistical Software with Audit Trail and Version Control </b>
                      <p style={{fontSize: "18px"}}>
                      Introduction: Understanding the Power of Statistical Software in Data Analysis
                      </p>
                      <p>In today's data-driven world, the ability to extract meaningful insights from vast amounts of information is a crucial competitive advantage. </p>
                      <div className="read_moreB">
                        <a href={"/maximizing-efficiency-and-accuracy"}>
                          <button>Read More</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col className="mb-3">
                <div className="mainServices1">
                  <div className="blog_date_data">
                    <div>
                      <img src={Complience} alt="" />
                    </div>
                    <div className="partners_maintime">
                      <div className="date_category">
                        <p className="mb-1">02/03/2024 | 01:32</p>
                        <p className="mb-1">
                          Category: Clinical Data Management
                        </p>
                      </div>
                      <b>Clinical Data Management </b>
                      <p>
                        At Galax Biotech, we understand the critical nature of
                        data management in the success of any clinical trial.
                        Our team of experienced professionals is dedicated to
                        providing you with top-notch services that will
                        streamline...
                      </p>
                      <div className="read_moreB">
                        <a href={"/maximizing-efficiency-and-accuracy"}>
                          <button>Read More</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="mb-3">
                <div className="mainServices1">
                  <div className="blog_date_data">
                    <div>
                      <img src={Complience} alt="" />
                    </div>
                    <div className="partners_maintime">
                      <div className="date_category">
                        <p className="mb-1">02/03/2024 | 01:32</p>
                        <p className="mb-1">
                          Category: Clinical Data Management
                        </p>
                      </div>
                      <b>Clinical Data Management </b>
                      <p>
                        At Galax Biotech, we understand the critical nature of
                        data management in the success of any clinical trial.
                        Our team of experienced professionals is dedicated to
                        providing you with top-notch services that will
                        streamline...
                      </p>
                      <div className="read_moreB">
                        <a href={"/maximizing-efficiency-and-accuracy"}>
                          <button>Read More</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="mb-3">
                <div className="mainServices1">
                  <div className="blog_date_data">
                    <div>
                      <img src={Complience} alt="" />
                    </div>
                    <div className="partners_maintime">
                      <div className="date_category">
                        <p className="mb-1">02/03/2024 | 01:32</p>
                        <p className="mb-1">
                          Category: Clinical Data Management
                        </p>
                      </div>
                      <b>Clinical Data Management </b>
                      <p>
                        At Galax Biotech, we understand the critical nature of
                        data management in the success of any clinical trial.
                        Our team of experienced professionals is dedicated to
                        providing you with top-notch services that will
                        streamline...
                      </p>
                      <div className="read_moreB">
                        <a href={"/maximizing-efficiency-and-accuracy"}>
                          <button>Read More</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="mb-3">
                <div className="mainServices1">
                  <div className="blog_date_data">
                    <div>
                      <img src={Complience} alt="" />
                    </div>
                    <div className="partners_maintime">
                      <div className="date_category">
                        <p className="mb-1">02/03/2024 | 01:32</p>
                        <p className="mb-1">
                          Category: Clinical Data Management
                        </p>
                      </div>
                      <b>Clinical Data Management </b>
                      <p>
                        At Galax Biotech, we understand the critical nature of
                        data management in the success of any clinical trial.
                        Our team of experienced professionals is dedicated to
                        providing you with top-notch services that will
                        streamline...
                      </p>
                      <div className="read_moreB">
                        <a href={"/maximizing-efficiency-and-accuracy"}>
                          <button>Read More</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Col> */}
            </div>
          )}
        </Container>
        {/* <Pagination className="justify-content-center">
          <Pagination.First />
          <Pagination.Prev />
          <Pagination.Item active>{1}</Pagination.Item>
          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Item>{3}</Pagination.Item>
          <Pagination.Ellipsis />
          <Pagination.Item>{9}</Pagination.Item>
          <Pagination.Next />
          <Pagination.Last />
        </Pagination> */}
      </div>
      <Footer />
    </div>
  );
}

export default BlogPage;
