import React from "react";
import "../CSS/offices.css";
import { Col, Container, Row } from "react-bootstrap";
// import Image1 from "../img/map.png";
import Product1 from "../img/product1.jpg";
import AppNavbar from "../Component/Navbars";
import Footer from "../Component/footer";
import { Helmet } from "react-helmet";

const OfficePage = () => {
  return (
    <>
     <Helmet>
        <title>Products</title>
        <meta
          name="description"
          content="Welcome to the future of clinical trial data capture and management with Galax EDC! We are thrilled to have you on board as we embark on this transformative journey together."
        />
        <meta
          name="keywords"
          content="Products, galaxbiotech"
        />
      </Helmet>
      <AppNavbar />
      <div className="capable_container">
        <div className="products1_pages">
          <Container>
            <Row className="contains gx-5">
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className="products1_rainvent">
                  <h2>Galax EDC</h2>
                  <p>
                    Welcome to the future of clinical trial data capture and
                    management with Galax EDC! We are thrilled to have you on
                    board as we embark on this transformative journey together.
                  </p>
                  <p>
                    Galax EDC has earned its reputation as the industry's
                    pinnacle due to its advanced, robust, and secure features.
                    Our cutting-edge technology is designed to streamline and
                    enhance the capture and management of clinical trial data
                    for sites, subjects, and labs.
                  </p>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className="products1_rainvent">
                  <img src={Product1} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="capabless_container">
        <div className="capability_pages">
          <Container>
            <h3>Here are some key features that set Galax EDC apart</h3>
            <Row style={{margin: "0 150px", paddingBottom: 60}}>
              <Col xl={6} lg={6} md={6} sm={12} className="mb-3 col-12">
                <div className="products_type">
                  <p className="m-0">
                    <b>Advanced Technology </b>
                  </p>
                  <p>
                    We leverage the latest advancements in technology to provide
                    you with a state-of-the-art platform. By incorporating
                    artificial intelligence and machine learning capabilities,
                    Galax EDC ensures accurate data collection while reducing
                    manual efforts
                  </p>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} className="mb-3 col-12">
                <div className="products_type">
                  <p className="m-0">
                    <b>Robust Functionality</b>
                  </p>
                  <p>
                    Our system is purposefully designed with robust features
                    that cater to the intricate needs of clinical research. From
                    comprehensive electronic case report forms (eCRFs) to
                    real-time data validation checks, Galax EDC empowers you
                    with tools for efficient data management.
                  </p>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} className="mb-3 col-12">
                <div className="products_type">
                  <p className="m-0">
                    <b>Enhanced Security </b>
                  </p>
                  <p>
                    At Galax EDC, we prioritize the security of your valuable
                    clinical trial data. Our platform adheres to
                    industry-leading security standards, ensuring your data
                    remains confidential and protected at all times.
                  </p>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} className="mb-3 col-12">
                <div className="products_type">
                  <p className="m-0">
                    <b>User-Friendly Interface</b>
                  </p>
                  <p>
                    Rigorous quality control measures are implemented throughout
                    the entire process to guarantee accuracy and reliability of
                    your data.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default OfficePage;
