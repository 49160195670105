import React from "react";
import "../CSS/producthome.css";

const Videobanner = () => {
  return (
    <div className="banerSection">
      <video autoPlay muted loop className="video-background">
        <source
          src="https://gas-admin-bucket.s3.ap-south-1.amazonaws.com/vides.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div id="home" className="banertextgroup banneroverlay words_back">
        <h1 className="welcome_test">
          <span style={{ fontSize: 30 }}>Welcome To</span> <br />
          Galax Biotech
        </h1>
      </div>
    </div>
  );
};

export default Videobanner;
