import React, { useState } from "react";
import "../CSS/form.css";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
const FormPage = () => {
  const [formData, setFormData] = useState({
    subject: "",
    name: "",
    email: "",
    enquiry: "",
  });

  const [custom, setCustom] = useState(false);
  const [checkbox, setCheckbox] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://staging-backend.galaxbiotech.com/api/sendMailForLandingPage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setFormData({
          subject: "",
          name: "",
          email: "",
          enquiry: "",
        });
        Swal.fire({
          title: "Success!",
          text: data.customMessage,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: data.customMessage || "Something went wrong!",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong!",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <div className="service-form" >
        <h2 className="conatcts1">Contact Us</h2>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="form-group">
            <label htmlFor="subject">Subject:</label>
            {!custom ? (
              <Form.Select
                onChange={handleChange}
                onClick={(e) =>
                  setCustom(e.target.value === "" ? true : false)
                }
                id="subject"
                name="subject"
                aria-label="Default select example"
              >
                <option>Select Subject</option>
                <option value="Services Enquiry">Services Enquiry</option>
                <option value="Product Demo">Product Demo</option>
                <option value="">Other</option>
              </Form.Select>
            ) : (
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            )}
          </div>
          <div className="form-group">
            <label htmlFor="name">Your Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Your Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="enquiry">Enquiry:</label>
            <textarea
              id="enquiry"
              name="enquiry"
              rows="5"
              value={formData.enquiry}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="remember">
              <input
                type="checkbox"
                id="remember"
                onClick={()=>setCheckbox(!checkbox)}
                className="rememberto w-auto"
              />{" "}
              <span className="accept">Accept terms and conditions</span>
            </label>
          </div>
          <button disabled={!checkbox} type="submit">Submit</button>
        </form>
      </div>
    </>
  );
};
export default FormPage;
