import React from "react";
import "../CSS/capabilities.css";
import { Col, Container, Row } from "react-bootstrap";
import FormPage from "./form";
import AppNavbar from "../Component/Navbars";
import Servi1 from "../img/clinicaldatamanagement.jpg";
import Documents from "../img/document.png";
import Manual from "../img/manualbook.png";
import Auditing from "../img/auditing.png";
import Tracking from "../img/tracking.png";
import Nonconfirm from "../img/non-conforming.png";
import Shakehands from "../img/shaking-hands.png";
import Footer from "../Component/footer";
import { Helmet } from "react-helmet";

const CapabilityPage = () => {
  return (
    <>
     <Helmet>
        <title>Clinical Data Management</title>
        <meta
          name="description"
          content="At Galax Biotech, we understand the critical nature of data management in the success of any clinical trial. Our team of experienced professionals is dedicated to providing you with top-notch services that will streamline your processes and ensure accurate, reliable data."
        />
        <meta
          name="keywords"
          content="Clinical, Data, Management, services, galaxbiotech"
        />
      </Helmet>
      <AppNavbar />
      <div className="capable_container">
        <div className="capable_pages">
          <Container>
            <Row className="contains gx-5">
              <Col md={6}>
                <div className="services_rainvent">
                  <p>
                    <b>Clinical Data Management</b>
                  </p>
                  <p>
                    At Galax Biotech, we understand the critical nature of data
                    management in the success of any clinical trial. Our team of
                    experienced professionals is dedicated to providing you with
                    top-notch services that will streamline your processes and
                    ensure accurate, reliable data.
                  </p>
                  <p>
                    Our team specializes in various aspects of clinical data
                    management.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="products1_rainvent">
                  <img src={Servi1} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="capabless_container">
        <div className="capability_pages">
          <Container>
            <div className="widgets_of_services">
              <Row>
                <Col md={4}>
                  <div className="service__wizards">
                    <img src={Documents} alt="" />
                    <h6>Case Report Forms (CRF) Designing</h6>
                    <p>
                      We will work closely with you to design intuitive and
                      user-friendly CRFs tailored to your specific study
                      requirements.
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="service__wizards">
                    <img src={Manual} alt="" />
                    <h6>Completion Guidelines</h6>
                    <p>
                      We can assist in developing clear completion guidelines
                      for CRFs, ensuring consistency and compliance throughout
                      the data collection process.
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="service__wizards">
                    <img src={Auditing} alt="" />
                    <h6>User Acceptance Testing (UAT)</h6>
                    <p>
                      Our experts will conduct thorough UAT to ensure that the
                      system meets your expectations and operates seamlessly.
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  {" "}
                  <div className="service__wizards">
                    <img src={Tracking} alt="" />
                    <h6>Tracking, Entry, and Verification</h6>
                    <p>
                      We offer streamlined processes for tracking, entering, and
                      verifying data, minimizing errors and maximizing
                      efficiency.
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="service__wizards">
                    <img src={Nonconfirm} alt="" />
                    <h6>Discrepancy Identification and Management</h6>
                    <p>
                      Our team employs advanced techniques to identify
                      discrepancies promptly, resolving them efficiently to
                      maintain data integrity.
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="service__wizards">
                    <img src={Shakehands} alt="" />
                    <h6>Serious Adverse Event (SAE) Reconciliation</h6>
                    <p>
                      Our dedicated SAE reconciliation procedures will ensure
                      accurate reporting while adhering to regulatory
                      guidelines.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <div className="capableity_container">
        <div className="capability_pages">
          <Container>
            <div className="widgets_of_services">
              <Row>
                <Col md={6}>
                  <div className="service__wizards">
                    <img src={Documents} alt="" />
                    <h6>Protocol Violations / Variations Review</h6>
                    <p>
                      We provide meticulous review processes to identify
                      protocol violations or variations promptly, enabling
                      timely corrective actions.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="service__wizards">
                    <img src={Manual} alt="" />
                    <h6>Quality Control (QC)</h6>
                    <p>
                      Rigorous quality control measures are implemented
                      throughout the entire process to guarantee accuracy and
                      reliability of your data.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="service__wizards">
                    <img src={Auditing} alt="" />
                    <h6>Data Mapping (CDISC-SDTM/ADAM)</h6>
                    <p>
                      Our experienced team ensures precise mapping of clinical
                      trial data according to CDISC standards for seamless
                      integration across different platforms.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="service__wizards">
                    <img src={Tracking} alt="" />
                    <h6>Accelerated Database Lock/Release</h6>
                    <p>
                    We expedite the database lock/release process, ensuring efficient closure of your clinical trial while maintaining data integrity.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <div className="capables1_containers1">
        <div className="capability_pages">
          <Container className="contains gx-5">
            <Row>
              <Col md={6}>
                <div className="services_rainvent">
                  <b>Request for services</b>
                  <p>
                    We would be delighted to discuss your specific requirements
                    further and provide a tailored solution that aligns with
                    your project goals. Please feel free to reach out to us at
                    any time via email or phone.
                  </p>
                  <p>
                    Thank you for considering our services. We look forward to
                    the opportunity of assisting you in optimizing your clinical
                    data management processes.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="services_rainvents1">
                  <FormPage />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default CapabilityPage;
