import React from "react";
import "../CSS/contact.css";
import { Container } from "react-bootstrap";
import AppNavbar from "../Component/Navbars";
import FormPage from "./form";
import Footer from "../Component/footer";
import { Helmet } from "react-helmet";

const ContactForm = () => {
  return (
    <>
     <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Get in touch with us today!"
        />
        <meta
          name="keywords"
          content="Contact, Us, galaxbiotech"
        />
      </Helmet>
      <AppNavbar />
      <Container>
        <FormPage />
      </Container>
      <Footer/>
    </>
  );
};

export default ContactForm;
