import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../CSS/producthome.css";
import { NavLink } from "react-router-dom";

function Producthomepage() {
  return (
    <Container>
      <Row>
        <Col id="product" className="d-flex justify-content-center">
          <div className="product_homes">
            <h2>Products</h2>
            <p>
              Welcome to the future of clinical trial data capture and
              management with Galax EDC!
            </p>
            <p>
              We are thrilled to have you on board as we embark on this
              transformative journey together.
            </p>
            <div className="product_reads">
              <NavLink to={"/products"}>
                {" "}
                <button>Read More</button>
              </NavLink>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Producthomepage;
