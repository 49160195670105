import React from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const AlreadyRegisteredMessage = () => {
  return (
    <>
      <div className="Form_justification">
        <div className="invite__form_message">
          <p>Alredy Registered</p>
          <div className="Success__taag mb-3">
            <i style={{ color: "green" }} class="fa-solid fa-circle-check" alt="Invitation Link Expired"></i>
          </div>
          <span>Your registration alredy completed. </span>
          <span>If you have any query. </span>
          <span>Feel free to contact "Administrator". </span>

        <div className="hstack gap-3 justify-content-center mt-4 footer_button">
          <NavLink to={'/login'}>
            <Button>Login</Button>
          </NavLink>
        </div>
        </div>
      </div>
    </>
  );
};

export default AlreadyRegisteredMessage;
