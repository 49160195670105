import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../CSS/abouthomepage.css";
import { NavLink } from "react-router-dom";

function Servicehomepage() {
  return (
    <Container>
      <Row>
        <Col id="service" className="d-flex justify-content-center">
          <div className="services_homes">
            <h2> Services</h2>
            <p>Clinical Data Management</p>
            <p>Biostatistics & Programming</p>
            <p>Data standardization</p>
            <div className="about_homes w-100">
              <NavLink to={"/service"}>
                {" "}
                <button>Read More</button>
              </NavLink>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Servicehomepage;
