import React from "react";
import "./App.css"
import Home from "./Component/home";
import AboutPage from "./Pages/about";
import OfficePage from "./Pages/offices";
import CareerPage from "./Pages/career";
import ContactForm from "./Pages/contact";
import WorkPage from "./Pages/work";
import Services2Page from "./Pages/services2";
import Services3Page from "./Pages/services3";
import Mainservices from "./Pages/mainService";
import PrivacyPage from "./Pages/privacypage";
import BlogPage from "./Pages/blog";
import { Routes, Route } from "react-router-dom";
import CapabilityPage from "./Pages/capabilities";
import TermsCondition from "./Pages/termscondition";
import CustomerSupport from "./Pages/customerpolicy";
import BlogDetailsData from "./Pages/blogPageDetails";
import ClientInvitationFormData from "./Component/Invitation/clientInvitaionForm";
import UserInvitationFormData from "./Component/Invitation/userInvitationForm";

function App() {
  const routes = [
    { path: "/", element: <Home /> },
    { path: "/about", element: <AboutPage /> },
    { path: "/products", element: <OfficePage /> },
    { path: "/clinical-data-management", element: <CapabilityPage /> },
    { path: "/careers", element: <CareerPage /> },
    { path: "/work", element: <WorkPage /> },
    { path: "/contact-us", element: <ContactForm /> },
    { path: "/biostatic-programming", element: <Services2Page /> },
    { path: "/data-standardization", element: <Services3Page /> },
    { path: "/service", element: <Mainservices /> },
    { path: "/privacy-policy", element: <PrivacyPage /> },
    { path: "/terms-conditions", element: <TermsCondition /> },
    { path: "/customer-support-policy", element: <CustomerSupport /> },
    { path: "/blog", element: <BlogPage /> },
    { path: "/client-invitation/:token", element: <ClientInvitationFormData /> },
    { path: "/user-invitation/:token", element: <UserInvitationFormData /> },
    {
      path: "/maximizing-efficiency-and-accuracy",
      element: <BlogDetailsData />,
    },
  ];

  const generateSitemap = () => {
    return routes.map((route) => route.path);
  };

  // Sitemap component
  const Sitemap = () => {
    const sitemap = generateSitemap();
    return (
      <div>
        <h1>Sitemap</h1>
        <ul>
          {sitemap.map((url, index) => (
            <li key={index}>
              <a href={url}>{url}</a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
        <Route path="/sitemap" element={<Sitemap />} />
      </Routes>
    </div>
  );
}

export default App;
