import React from "react";
import "../CSS/careers.css";
import { Col, Container, Row } from "react-bootstrap";
import AppNavbar from "../Component/Navbars";
import Footer from "../Component/footer";

const CareerPage = () => {
  return (
    <>
      <AppNavbar />
      <div className="career_container">
        <div className="careers_pages">
          <Container>
            <Row className="contains">
              <Col md={6}>
                <div className="rainvent">
                  <p>We reinvent medical communications to</p>
                  <b>solve your toughest challenges.</b>
                </div>
              </Col>
              <Col md={6}>
                <div className="years">
                  <p>
                    For over 25 years, we’ve been pioneering new ways of
                    thinking. We are a team of <b>creators</b>, <b>pioneers</b>{" "}
                    and <b>leaders</b>. Always demanding more of ourselves,
                    proudly making it matter.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="careers_container">
        <div className="careers_pages">
          <Container className="contains">
            <Row>
              <Col md={6}>
                <div className="rainvent">
                  <p>We reinvent medical communications to</p>
                  <b>solve your toughest challenges.</b>
                </div>
              </Col>
              <Col md={6}>
                <div className="years">
                  <p>
                    For over 25 years, we’ve been pioneering new ways of
                    thinking. We are a team of creators, pioneers and leaders.
                    Always demanding more of ourselves, proudly making it
                    matter.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="careerst_container">
        <div className="careers_pages">
          <Container className="contains">
            <Row>
              <Col md={6}>
                <div className="rainvent">
                  <p>We reinvent medical communications to</p>
                  <b>solve your toughest challenges.</b>
                </div>
              </Col>
              <Col md={6}>
                <div className="years">
                  <p>
                    For over 25 years, we’ve been pioneering new ways of
                    thinking. We are a team of creators, pioneers and leaders.
                    Always demanding more of ourselves, proudly making it
                    matter.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="careers1_containers1">
        <div className="careers_pages">
          <Container className="contains">
            <Row>
              <Col md={6}>
                <div className="rainvent">
                  <p>We reinvent medical communications to</p>
                  <b>solve your toughest challenges.</b>
                </div>
              </Col>
              <Col md={6}>
                <div className="years">
                  <p>
                    For over 25 years, we’ve been pioneering new ways of
                    thinking. We are a team of creators, pioneers and leaders.
                    Always demanding more of ourselves, proudly making it
                    matter.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="careers_pages">
        <Container className="contains">
          <Row>
            <Col md={6}>
              <div className="rainvent">
                <p>We reinvent medical communications to</p>
                <b>solve your toughest challenges.</b>
              </div>
            </Col>
            <Col md={6}>
              <div className="years ">
                <p>
                  We are proudly part of Inizio. Inizio is a strategic partner
                  for companies in health and life sciences that connects a full
                  suite of advisory, medical, marketing, communications, and
                  patient and stakeholder engagement services across the
                  lifecycle of a drug. Visit www.inizio.health for more
                  information.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="careersst"></div>
      <Footer/>
    </>
  );
};
export default CareerPage;
