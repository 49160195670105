import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../CSS/abouthomepage.css";
import { NavLink } from "react-router-dom";

function Abouthomepage() {
  return (
    <Container>
      <Row>
        <Col id="about" className="d-flex justify-content-center">
          <div className="about_homes">
            <h2>About Us</h2>
            <p className="pioneerabout">
              Pioneering the Future of Clinical Trials with AI-Driven
              CTMS Solutions.
            </p>
            <div className="our_commit">
              <p>
                Our commitment lies in offering advanced, AI-poweredtools and
              </p>
              <p>
                comprehensive EDC software solutions to facilitate
                seamless end-to-end clinical trial management.
              </p>
            </div>
            <div className="about_reads">
              <NavLink to={"/about"}>
                <button>Read More</button>
              </NavLink>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Abouthomepage;
