import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../CSS/footer.css";
import Indiaflag from "../img/indiflag.png";
import Maxicoflag from "../img/flag.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="whole_footer">
      <Container>
        <Row className="rows_part py-4 border-bottom">
          <Col md={3}>
            <h5 className="titles">Company</h5>
            <p>
              <NavLink to={"/about"}>About Us</NavLink>
            </p>
            <p>
              <NavLink to={"/products"}>Products</NavLink>
            </p>
            <p>
              <NavLink to={"/service"}>Services</NavLink>
            </p>
            <p>
              <NavLink to={"/contact-us"}>Contact Us</NavLink>
            </p>
          </Col>
          <Col md={3}>
            <h5 className="titles">Support</h5>
            <p>
              <NavLink to={"/terms-conditions"}>Terms of Use</NavLink>
            </p>
            <p>
              <NavLink to={"/privacy-policy"}>Privacy Statements</NavLink>
            </p>
            <p>
              <NavLink to={"/customer-support-policy"}>
                Customer Support Policy
              </NavLink>
            </p>
          </Col>
          <Col md={4} className="location_icon">
            <h5 className="titles">Geographical Region</h5>
              <p>
                <img src={Indiaflag} className="indiaflag" alt="" /> Bengaluru,
                India
              </p>
            <br />
            <p>
              <img src={Maxicoflag} alt="" /> Monterrey, Mexico
            </p>
          </Col>
          <Col md={2}>
            <h5 className="titles">Connect With Us</h5>
            <div className="hstack gap-4">
              <div className="icon_type_what">
                <a
                  href="https://wa.link/k1a10i"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
              </div>
              <div className="icon_type_link">
                <a
                  href="https://www.linkedin.com/company/galaxbiotech"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <div className="class_footer">
          <p className="m-0">Copyright © Galax Biotech</p>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
