import React from "react";
import { Navbar, Nav, Button, Container, Dropdown } from "react-bootstrap";
import "../CSS/navbar.css";
import { NavLink } from "react-router-dom";
import Blacklogo from "../img/biotechblack.png";

const AppNavbar = () => {
  return (
    <div className="navbars">
      <Navbar bg="light" expand="lg" style={{ zIndex: 9 }}>
        <Container>
          <Navbar.Brand href="/">
            <div className="main_logos">
              <img src={Blacklogo} alt="" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end align-items-center"
            style={{ marginRight: 200 }}
          >
            <Nav className="mr-auto" style={{ gap: 50 }}>
              <NavLink to={"/about"}>About</NavLink>
              <NavLink to={"/products"}>Products</NavLink>
              <Dropdown>
                <Dropdown.Toggle
                  className="hstack"
                  variant="none"
                  id="dropdown-basic"
                >
                  <NavLink to={"/service"}>Services</NavLink>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="clinical-data-management">
                    Clinical Data Management
                  </Dropdown.Item>
                  <Dropdown.Item href="biostatic-programming">
                    Biostatistics & Programming
                  </Dropdown.Item>
                  <Dropdown.Item href="data-standardization">
                    Data standardization
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <NavLink to={"/blog"}>Blog</NavLink>
              <NavLink to={"/contact-us"}>Contact Us</NavLink>
            </Nav>
            <div className="buttons" style={{ marginLeft: 50 }}>
              <NavLink to={"/contact-us"}>
                <Button>Request Demo</Button>
              </NavLink>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default AppNavbar;
