import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { invitedDataServices } from "../../APIServices";
import { useNavigate, useParams } from "react-router";
import validator from "validator";
import ExpiredInvitationMessage from "./ExpiredInvitationMessage";
import AlreadyRegisteredMessage from "./AlreadyRegisteredMessage";
import InviteConfirmation from "./InviteConfirmation";

const ClientInvitationFormData = () => {
  const { token } = useParams();
  const [fullName, setFullName] = useState("");
  const [shortName, setShortName] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [requestStatus, setRequestStatus] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [office_phone, setOfficePhone] = useState("");

  const [cityLists, setCityLists] = useState([]);
  const [city_id, setCityID] = useState("");

  const [stateLists, setStateLists] = useState([]);
  const [state_id, setStateID] = useState("");

  const [countryLists, setCountryLists] = useState([]);
  const [country_id, setCountryID] = useState("");

  const [postalCode, setPostalCode] = useState("");
  const [databaseType, setDatabaseType] = useState("");
  const [databaseUser, setDatabaseUser] = useState("");
  const [databasePassword, setDatabasePassword] = useState("");
  const [databaseName, setDatabaseName] = useState("");
  const [databaseHost, setDatabaseHost] = useState("");
  const [statusCode, setStatusCode] = useState(false);

  // const [validated, setValidated] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    getCountries();
    getInvitationData();
  }, []);

  const getInvitationData = async () => {
    let data = await invitedDataServices.getInvitationData(token);
    setEmail(data?.email)
    setStatus(data?.status)
    setRequestStatus(data?.requestStatus)
  };

  const getCountries = async (e) => {
    let data = await invitedDataServices.getCountries(e);
    setCountryLists(data);
  };

  const getStates = async (e) => {
    let data = await invitedDataServices.getStates(e);
    setStateLists(data);
  };

  const getCities = async (e) => {
    let data = await invitedDataServices.getCities(e);
    setCityLists(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      e.stopPropagation();

      if (validator.isEmail(email)) {
        let userData = {};
        userData.token = token;
        userData.firstName = fullName;
        userData.shortName = shortName;
        userData.website = website;
        userData.address = address;
        userData.email = email;
        userData.phone = phone;
        userData.office_phone = office_phone;
        userData.city_id = city_id;
        userData.state_id = state_id;
        userData.country_id = country_id;
        userData.postalCode = postalCode;
        userData.db_type = databaseType;
        userData.db_name = databaseName;
        if (databaseUser === "own") {
          userData.db_user = databaseUser;
          userData.db_password = databasePassword;
          userData.db_host = databaseHost;
        }
        let data = await invitedDataServices.invitedClientRegistration(userData, history);
        if (data?.statusCode === 200) {
          setFullName("");
          setShortName("");
          setWebsite("");
          setEmail("");
          setPhone("");
          setAddress("");
          setOfficePhone("");
          setPostalCode("");
          setCityID("");
          setStateID("");
          setCountryID("");
          setDatabaseType("");
          setDatabaseUser("");
          setDatabasePassword("");
          setDatabaseName("");
          setDatabaseHost("");
          setStatusCode(true)
        }
      }
    }
    // setValidated(true);
  };
  return (
    <>
      {statusCode ? <InviteConfirmation /> :
        status === "invited" && requestStatus === "approved" ?
          <div>
            <Container className="Invitation_form mt-5 mb-5">
              <div className="invite_forms">
                <h3 className="py-4 m-0">Client Invitation</h3>
              </div>
              <Form>
                <Row>
                  <Col md={7}>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="mb-3" md="6" controlId="validationCustom01">
                        <Form.Label> <b>Company Name
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Company name"
                          onChange={(e) => setFullName(e.target.value)}
                          value={fullName}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" md="6" controlId="validationCustom02">
                        <Form.Label> <b>Company Short Name
                        </b></Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Company Short"
                          onChange={(e) => setShortName(e.target.value)}
                          value={shortName}
                        />
                      </Form.Group>

                      <Form.Group as={Col} className="mb-3" md="12" controlId="validationCustom03">
                        <Form.Label> <b>Company Website
                        </b></Form.Label>
                        <Form.Control
                          type="url"
                          placeholder="Company Website"
                          onChange={(e) => setWebsite(e.target.value)}
                          value={website}
                        />
                      </Form.Group>

                      <Form.Group as={Col} className="mb-3" md="12" controlId="validationCustom04">
                        <Form.Label><b> Company Address
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter Address"
                          style={{ height: "63px" }}
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                        />
                      </Form.Group>

                      <Form.Group as={Col} className="mb-3" md="6" controlId="validationCustom05">
                        <Form.Label> <b>Country
                          <span>*</span></b></Form.Label>
                        <Form.Select aria-label="Default select example"
                          onChange={(e) => setCountryID(e.target.value)}
                          value={country_id}
                          onClick={(e) =>
                            getStates(e.target[e.target.selectedIndex].title)
                          }
                        >
                          <option>-Select-</option>
                          {countryLists.length > 0
                            ? countryLists.map((item, key) => (
                              <option
                                value={item._id}
                                key={key}
                                title={item.id}
                              >
                                {item.name}
                              </option>
                            ))
                            : ""}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group as={Col} className="mb-3" md="6" controlId="validationCustom05">
                        <Form.Label> <b>State
                          <span>*</span></b></Form.Label>
                        <Form.Select aria-label="Default select example"
                          onChange={(e) => setStateID(e.target.value)}
                          value={state_id}
                          onClick={(e) =>
                            getCities(e.target[e.target.selectedIndex].title)
                          }
                        >
                          <option>-Select-</option>
                          {stateLists.length > 0
                            ? stateLists.map((item, key) => (
                              <option
                                value={item._id}
                                key={key}
                                title={item.id}
                              >
                                {item.name}
                              </option>
                            ))
                            : ""}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group as={Col} className="mb-3" md="6" controlId="validationCustom05">
                        <Form.Label> <b>City
                          <span>*</span></b></Form.Label>
                        <Form.Select aria-label="Default select example"
                          onChange={(e) => setCityID(e.target.value)}
                          value={city_id}
                        >
                          <option>-Select-</option>
                          {cityLists.length > 0
                            ? cityLists.map((item, key) => (
                              <option value={item._id} key={key}>
                                {item.name}
                              </option>
                            ))
                            : ""}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group as={Col} className="mb-3" md="6" controlId="validationCustom05">
                        <Form.Label> <b>Postal Code
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="Enter Postel Code"
                          onChange={(e) => setPostalCode(e.target.value)}
                          value={postalCode}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" md="6" controlId="validationCustom05">
                        <Form.Label> <b>Mobile Number
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="Enter Mobile Number"
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" md="6" controlId="validationCustom05">
                        <Form.Label> <b>Office Phone
                        </b></Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Office Phone"
                          onChange={(e) => setOfficePhone(e.target.value)}
                          value={office_phone}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Form.Group as={Col} className="mb-3" md="12" controlId="validationCustom05">
                      <Form.Label> <b>Email Id
                        <span>*</span></b></Form.Label>
                      <Form.Control
                        required
                        type="email"
                        disabled
                        placeholder="Enter Email Id"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </Form.Group>
                    <Form.Label> <b>Client Database Details :
                    </b></Form.Label>
                    <Form.Group as={Col} className="mb-3" md="12" controlId="validationCustom05">
                      <Form.Label> <b>Database Type
                        <span>*</span></b></Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => setDatabaseType(e.target.value)}
                      >
                        <option>-Select-</option>
                        <option value="own">Own Database</option>
                        <option value="our">Our Database</option>
                      </Form.Select>
                    </Form.Group>
                    {databaseType === "own" &&
                      <Form.Group as={Col} className="mb-3" md="12" controlId="validationCustom05">
                        <Form.Label> <b>Database User
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter Database User"
                          onChange={(e) => setDatabaseUser(e.target.value)}
                          value={databaseUser}
                        />
                      </Form.Group>
                    }
                    {databaseType === "own" &&
                      <Form.Group as={Col} className="mb-3" md="12" controlId="validationCustom05">
                        <Form.Label> <b>Database Password
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter Database Password"
                          onChange={(e) => setDatabasePassword(e.target.value)}
                          value={databasePassword}
                        />
                      </Form.Group>
                    }
                    {databaseType === "own" &&
                      <Form.Group as={Col} className="mb-3" md="12" controlId="validationCustom05">
                        <Form.Label> <b>Database Host
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter Database Host"
                          onChange={(e) => setDatabaseHost(e.target.value)}
                          value={databaseHost}
                        />
                      </Form.Group>
                    }
                    {databaseType &&
                      <Form.Group as={Col} className="mb-3" md="12" controlId="validationCustom05">
                        <Form.Label> <b>Database Name
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter Database Name"
                          onChange={(e) => setDatabaseName(e.target.value)}
                          value={databaseName}
                        />
                      </Form.Group>
                    }

                    <Form.Group as={Col} className="mb-3 text-end" md="12" controlId="validationCustom05">
                      {databaseType === "our" ?
                        <Button variant="success" className="w-25"
                          disabled={!token || !fullName || !shortName || !website || !address || !phone || !office_phone || !city_id || !state_id || !country_id || !postalCode || !databaseType || !databaseName}
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                        :
                        <Button variant="success" className="w-25"
                          disabled={!token || !fullName || !shortName || !website || !address || !phone || !office_phone || !city_id || !state_id || !country_id || !postalCode || !databaseType || !databaseUser || !databasePassword || !databaseName || !databaseHost}
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      }
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Container>
          </div>
          :
          status === "registered" ?
            <AlreadyRegisteredMessage />
            :
            status === "cancelled" ?
              <ExpiredInvitationMessage />
              :
              <ExpiredInvitationMessage />
      }
    </>
  );
};
export default ClientInvitationFormData;
