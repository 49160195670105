import React from "react";
import "../CSS/work.css";
import Image23 from "../img/fifth.jpg";
import { Col, Container, Row } from "react-bootstrap";
import AppNavbar from "../Component/Navbars";
import Footer from "../Component/footer";
const WorkPage = () => {
  return (
    <>
      <AppNavbar />
      <div className="works_types">
        <Container>
          <Row>
            <Col md={6}>
              <div className="work">
                <p>
                  This is how <b>we make science matter</b> from lab discovery
                  to real-life outcomes.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <p className="wework">
                  We work across 425+ products from discovery to maturity and
                  across 100+ therapy area indications.
                </p>
                <b className="bolds"> In a typical year we deliver…</b>
                <p className="strategy">
                  50+ strategic management projects, 1000+ satellite,
                  standalone, investigator, or training meetings, 4500+
                  publication plans and medical education assets, and 700+
                  electronic/digital projects.
                </p>
                <b className="bolds">This also includes:</b>
                <p className="strategy">
                  100+ events, 70+ exhibitions, 500+ advisory boards, 950+ slide
                  presentations, 670+ internal meetings, and 140+ promotional
                  materials.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="image_section">
        <img src={Image23} alt="" />
        <div className="text_center">
          <Row>
            <Col md={6}>
              <div className="topics">
                <p>EDC Topic</p>
              </div>
            </Col>
            <Col md={6}></Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default WorkPage;
