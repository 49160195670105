import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AppNavbar from "../Component/Navbars";
import Footer from "../Component/footer";
import "../CSS/global.css";
import { Helmet } from "react-helmet";
const TermsCondition = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions</title>
        <meta
          name="description"
          content="Welcome to the website and application owned by galaxbiotech. By accessing and using our Services through this website, you agree to be bound by the following terms and conditions."
        />
        <meta name="keywords" content="terms, and, condition, galaxbiotech" />
      </Helmet>
      <AppNavbar />
      <div className="termsbanerSection">
        <div className="banertextgroup banneroverlay ">
          <h1>Terms & Conditions</h1>
        </div>
      </div>
      <Container>
        <Row className="Privacy-12">
          <Col>
            <h2>Terms & Conditions</h2>
            <p>
              Welcome to the website and application owned by galaxbiotech. By
              accessing and using our Services through this website, you agree
              to be bound by the following terms and conditions. If you do not
              agree to these terms, please refrain from using the website and
              our services.
            </p>
            <h2>Changes to these Terms</h2>
            <p>
              We reserve the right to modify, add, remove, or alter sections of
              these terms and conditions at our discretion. Continued use of the
              website after changes are posted constitutes your acceptance of
              the revised terms. Please check this page periodically for
              updates.
            </p>
            <h2>Content Ownership</h2>
            <p>
              All elements of the Content on this website, including text,
              graphics, visuals, trademarks, logos, sounds, and computer code,
              are the property of galaxbiotech and are protected by copyright,
              trademark, and other relevant laws. You are not authorized to
              misuse or violate this Content, and galaxbiotech will take
              appropriate measures to enforce and protect its ownership rights.
            </p>
            <h2>Defined Terms</h2>
            <p>
              References to “galaxbiotech,” “we,” “us,” or “our” pertain to
              galaxbiotech. The term “Services” encompasses the offerings
              provided by galaxbiotech, as outlined in clause 5.
            </p>
            <h2>Use of Website and Services</h2>
            <p>
              As a user of this website or a registered user of our Services,
              you agree to:
            </p>
            <ul>
              <li>
                <p>
                  Exclusive Use: Use your account solely for your business
                  needs, not share your credentials, and not authorize others to
                  access your account without our consent. galaxbiotech is not
                  responsible for unauthorized access resulting from theft or
                  misuse of your account details.
                </p>
              </li>
              <li>
                <p>
                  Information Submitted: Take full responsibility for all
                  information and content you submit through the website or
                  Services, as well as your interactions with third parties.
                </p>
              </li>
              <li>
                <p>
                  Risk Assumption and Precautions: Recognize the inherent risks
                  when using our Services, including interactions with
                  third-party service providers.
                </p>
              </li>
              <li>
                <p>
                  No Guarantees: Acknowledge that we may not always find a
                  suitable match for your needs, and we do not guarantee a
                  specific number of matches or provider availability.
                </p>
              </li>
              <li>
                <p>
                  Accurate Information: Commit to providing accurate, truthful,
                  current, and complete information when using our website,
                  application, or Services.
                </p>
              </li>
              <li>
                <p>
                  Legal and Business Use: Agree to use the website, application,
                  and Services only for lawful and bona fide business purposes,
                  in line with applicable laws.
                </p>
              </li>
              <li>
                <p>
                  Business Purpose: Utilize our Services solely for legitimate
                  business purposes and not for personal matters. When
                  registering on behalf of a business, ensure you have the
                  authority to bind the business to these terms.
                </p>
              </li>
            </ul>
            <h2>Proprietary Rights</h2>
            <ul>
              <li>
                <p>
                  Ownership of Proprietary Information: Acknowledge that
                  galaxbiotech owns all rights to the website, application, and
                  Services. You may only use them as authorized by these terms
                  and conditions, refraining from copying, reproducing, or
                  reverse engineering.
                </p>
              </li>
              <li>
                <p>
                  Confidential Information: Do not disclose, distribute, or
                  reproduce confidential information or other copyrighted
                  material accessible via our Services without proper
                  authorization.
                </p>
              </li>
              <li>
                <p>
                  License to Provided Content: Grant us a non-exclusive,
                  perpetual license to use, reproduce, display, and distribute
                  information and content you provide on our platform.
                </p>
              </li>
              <li>
                <p>
                  Enhancements: Participate in new features or services at your
                  discretion, understanding that your involvement grants us
                  certain rights as stated in this subsection.
                </p>
              </li>
            </ul>
            <h2>User Information</h2>
            <ul>
              <li>
                <p>
                  Privacy Policy: Review our Privacy Policy for information on
                  data collection and usage practices, as your use of our
                  Services implies consent to our privacy practices.
                </p>
              </li>
              <li>
                <p>
                  Disclosure by Law: Understand that we may disclose your
                  information if legally required or in response to valid
                  requests from law enforcement or third parties.
                </p>
              </li>
              <li>
                <p>
                  Information Security: We take measures to safeguard your
                  information and maintain its confidentiality.
                </p>
              </li>
              <li>
                <p>
                  Anonymous Information for Research: Agree to allow us to use
                  your information and experiences to enhance our Services and
                  research business practices.
                </p>
              </li>
            </ul>
            <h2>Services</h2>
            <p>
              We assist businesses by offering proprietary reports and gathering
              business information through our Services. By using our Services,
              you permit the electronic transfer of information to specific
              providers as facilitated by our system.
            </p>
            <h2>
              Links to Third-Party Websites / Dealings with Advertisers and
              Sponsors
            </h2>
            <p>
              Our website may include links to third-party sites, but we are not
              responsible for their content or any interactions you have with
              them. Your dealings with third-party advertisers or sponsors are
              solely between you and them.
            </p>
            <h2>Disclaimer</h2>
            <ul>
              <li>
                <p>
                  No Warranties: We provide our Services “as is” and make no
                  warranties regarding their availability, security, or
                  uninterrupted usage.
                </p>
              </li>
              <li>
                <p>
                  Third-Party Content: Content from authors other than
                  galaxbiotech should not be solely relied upon, and we disclaim
                  liability for any reliance on such content.
                </p>
              </li>
              <p>
                Beta Features: Experimental features are offered without
                warranty and may be modified or discontinued at our discretion.
              </p>
            </ul>
            <h2>Limitation of Liability</h2>
            <p>
              We are not liable for incidental, consequential, or indirect
              damages arising from your use of our Services. To the extent
              permitted by law, we disclaim liability for any damages resulting
              from your conduct or actions.
            </p>
            <h2>Indemnification</h2>
            <p>
              You agree to indemnify and hold galaxbiotech harmless from any
              losses, costs, and liabilities arising from your use of the
              website, application, or Services, including any violation of
              these terms or third-party rights.
            </p>
            <h2>Communication and Privacy</h2>
            <p>
              We may use your email for important notifications and may contact
              you via telephone if provided. Refer to our Privacy Policy if you
              want to manage your communications preferences.
            </p>
            <h2>Terms and Termination</h2>
            <p>
              This agreement remains in effect until terminated by either party.
              We reserve the right to suspend or terminate your access without
              notice, and you agree that outstanding fees remain non-refundable.
            </p>
            <h2>General Provisions</h2>
            <ul>
              <li>
                <p>
                  Controlling Law and Jurisdiction: These terms are governed by
                  the laws of New South Wales, and you consent to the exclusive
                  jurisdiction of its courts. In case of violation, we may seek
                  legal remedies without posting a bond.
                </p>
              </li>
              <li>
                <p>
                  Miscellaneous: These terms constitute the entire agreement
                  between you and galaxbiotech, superseding prior agreements.
                  FAQ content is for informational purposes only and not part of
                  these terms.
                </p>
              </li>
              <li>
                <p>
                  Survival: Certain provisions remain effective even after your
                  registration is terminated.
                </p>
              </li>
              <li>
                <p>
                  Non-waiver: Our failure to enforce any right or provision does
                  not waive our rights.
                </p>
              </li>
              <li>
                <p>
                  Severability: If any provision is deemed invalid, the
                  remainder of the terms remains in effect.
                </p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
export default TermsCondition;
