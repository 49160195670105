import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../CSS/global.css";
import AppNavbar from "../Component/Navbars";
import Footer from "../Component/footer";
import { Helmet } from "react-helmet";

const PrivacyPage = () => {
  return (
    <>
     <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Welcome to the website and application owned by galaxbiotech. By accessing and using our Services through this website, you agree to be bound by the following terms and conditions."
        />
        <meta name="keywords" content="privacy, policy, galaxbiotech" />
      </Helmet>
      <AppNavbar />
      <div className="privacybanerSection">
        <div className="banertextgroup banneroverlay">
          <h1>Privacy Policy</h1>
        </div>
      </div>
      <Container>
        <Row className="Privacy-12">
          <Col>
            <h2>Privacy Policy</h2>
            <p>
              This privacy notice pertains to galaxbiotech and its affiliated
              entities (“galaxbiotech“), collectively referred to as “we,” “us,”
              or “our” within this document.
            </p>
            <p>
              At galaxbiotech, we are dedicated to upholding your privacy
              according to the Indian Privacy Principles. This policy outlines
              our approach to the collection and handling of personal
              information. We understand your concerns as a valued customer and
              your need for confidentiality and security regarding the data we
              may hold about you.
            </p>
            <p>
              By using our services and/or accessing our website, you signify
              your acceptance of this policy and agree to the collection, usage,
              and disclosure of your personal information as described herein.
            </p>
            <h2>1 . Definition of Personal Information</h2>
            <p>
              Personal information encompasses data or opinions that can
              identify an individual, either directly or reasonably.
            </p>
            <h2>2. Transparent Personal Information Management</h2>
            <p>
              We are committed to transparently managing the personal
              information we hold. Adhering to the Indian Privacy Principles and
              relevant codes, we’ve established processes to ensure compliance.
              Our updated privacy policy details how we handle personal
              information, including the types of data collected, collection
              reasons, access and correction procedures, complaints resolution,
              and our approach to disclosing information to overseas recipients.
            </p>
            <h2>3. Collection of Personal Information</h2>
            <p>
              We collect personal information through various means, including
              direct inputs on our website, information provided directly to us,
              or communicated via other channels. Additional information may be
              gathered during feedback submissions, disclosure of personal or
              business details, changes in content or email preferences, survey
              responses, promotional engagement, provision of financial or
              credit card information, or interaction with our customer support.
            </p>
            <p>
              We solely collect personal information directly from individuals
              when reasonably required and through lawful and equitable means.
              We ensure that the purpose of information collection is clear, and
              your right to access such information is acknowledged.
              Non-provision of requested information may impact our ability to
              offer services.
            </p>
            <h2>4. Methods of Personal Information Collection</h2>
            <p>
              We acquire personal information through electronic or in-person
              interactions, website usage, and service delivery. We may also
              receive personal information from third parties, ensuring its
              protection as per this policy, unless otherwise specified by you.
            </p>
            <h2>5. Unsolicited Personal Information</h2>
            <p>
              Unsolicited personal information is retained solely when necessary
              for our services, following lawful and reasonable protocols.
            </p>
            <h2>6. Usage of Personal Information</h2>
            <p>
              The primary purpose of using collected personal information is to
              deliver our services. Secondary purposes may encompass the
              provision of information, updates, and additional services. We may
              also inform you about new products, services, or opportunities.
            </p>
            <h2>7. Direct Marketing</h2>
            <p>
              We may utilize personal information for direct marketing if
              obtained from you and if it’s reasonably anticipated. You have the
              option to opt out of marketing communications. Opt-out mechanisms
              are provided in each communication. Legal notifications, if
              required, will continue even if you opt out.
            </p>
            <h2>8. Disclosure of Personal Information</h2>
            <p>
              We may disclose personal information to employees, insurers,
              advisers, agents, suppliers, or subcontractors where reasonably
              necessary and in alignment with this document’s objectives.
              Disclosure may also occur to third parties to facilitate our
              services. Information exchange may involve professional advisers,
              external service providers, government agencies, financial
              institutions, insurers, or debt collectors. Disclosure to overseas
              entities or individuals is strictly for transaction facilitation
              or legal obligations, encompassing overseas intermediary banks,
              branches, and service providers. Disclosures may also be made as
              required or authorized by law.
            </p>
            <p>
              Your provision of personal information indicates your consent to
              the terms of this Privacy Policy and the covered types of
              disclosure.
            </p>
            <h2>9. Security of Personal Information</h2>
            <p>
              We are dedicated to securing the information you provide. We’ve
              implemented appropriate physical, electronic, and managerial
              measures to protect against unauthorized access, misuse, loss, or
              disclosure. Password access is enforced for electronic databases,
              and information is destroyed or de-identified when no longer
              needed.
            </p>
            <p>
              While we take precautions, we can’t ensure the absolute security
              of data transmission. We can’t guarantee that collected personal
              information won’t be disclosed inconsistently with this policy.
            </p>
            <h2>10. Access to Personal Information</h2>
            <p>
              You have the right to request access to your personal information
              in accordance with the law. We maintain reasonable efforts to
              ensure the accuracy, currency, and completeness of personal
              information.
            </p>
            <p>
              If information is deemed inaccurate, outdated, incomplete,
              irrelevant, or misleading, we will promptly correct it.
            </p>
            <h2>11. Privacy Complaints</h2>
            <p>
              For privacy-related concerns, contact us at contact@galaxbiotech.
              We’ll investigate your complaint and work towards resolution.
            </p>
            <h2>12. Changes to Privacy Policy</h2>
            <p>
              Please be aware that we may modify this Privacy Policy in the
              future. Changes will be effective upon posting on our website.
            </p>
            <h2>13. Website & Cookies</h2>
            <p>
              When visiting galaxbiotech, certain information such as browser
              type and operating system may be collected for aggregated analysis
              to enhance services.
            </p>
            <p>
              Cookies may be used on our website to identify you upon return and
              store usage details. While most browsers accept cookies, you can
              choose to reject them, potentially impacting website
              functionality. Cookies assist in analyzing website traffic for
              improved user experiences.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </>
  );
};
export default PrivacyPage;
