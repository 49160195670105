import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { invitedDataServices } from "../../APIServices";
import { useNavigate, useParams } from "react-router";
import validator from "validator";
import AlreadyRegisteredMessage from "./AlreadyRegisteredMessage";
import ExpiredInvitationMessage from "./ExpiredInvitationMessage";
import InviteConfirmation from "./InviteConfirmation";

const UserInvitationFormData = () => {
  const { token } = useParams();
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [requestStatus, setRequestStatus] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [office_phone, setOfficePhone] = useState("");

  const [cityLists, setCityLists] = useState([]);
  const [city_id, setCityID] = useState("");

  const [stateLists, setStateLists] = useState([]);
  const [state_id, setStateID] = useState("");

  const [countryLists, setCountryLists] = useState([]);
  const [country_id, setCountryID] = useState("");

  const [postalCode, setPostalCode] = useState("");
  const [statusCode, setStatusCode] = useState(false);

  // const [validated, setValidated] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    getCountries();
    getInvitationData();
  }, []);

  const getInvitationData = async () => {
    let data = await invitedDataServices.getInvitationData(token);
    console.log(">>>data", data);
    setEmail(data?.email)
    setStatus(data?.status)
    setRequestStatus(data?.requestStatus)
  };

  const getCountries = async (e) => {
    let data = await invitedDataServices.getCountries(e);
    setCountryLists(data);
  };

  const getStates = async (e) => {
    let data = await invitedDataServices.getStates(e);
    setStateLists(data);
  };

  const getCities = async (e) => {
    let data = await invitedDataServices.getCities(e);
    setCityLists(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      e.stopPropagation();

      if (validator.isEmail(email)) {
        let userData = {};
        userData.token = token;
        userData.firstName = firstName;
        userData.middleName = middleName;
        userData.lastName = lastName;
        userData.address = address;
        userData.email = email;
        userData.phone = phone;
        userData.office_phone = office_phone;
        userData.city_id = city_id;
        userData.state_id = state_id;
        userData.country_id = country_id;
        userData.postalCode = postalCode;
        let data = await invitedDataServices.invitedUserRegistration(userData, history);
        if (data?.statusCode === 200) {
          setFirstName("");
          setMiddleName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setAddress("");
          setOfficePhone("");
          setPostalCode("");
          setCityID("");
          setStateID("");
          setCountryID("");
          setStatusCode(true)
        }
      }
    }
    // setValidated(true);
  };
  return (
    <>
      {statusCode ? <InviteConfirmation /> :
        status === "invited" && requestStatus === "approved" ?
          <div>
            <Container className="Invitation_form mt-5 mb-5">
              <div className="invite_forms">
                <h3 className="py-4 m-0">User Invitation</h3>
              </div>
              <Form>
                <Row>
                  <Col md={7}>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="mb-3" md="4" controlId="validationCustom01">
                        <Form.Label> <b>First Name
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="First name"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" md="4" controlId="validationCustom02">
                        <Form.Label> <b>Middle Name
                        </b></Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Middle Name"
                          onChange={(e) => setMiddleName(e.target.value)}
                          value={middleName}
                        />
                      </Form.Group>

                      <Form.Group as={Col} className="mb-3" md="4" controlId="validationCustom02">
                        <Form.Label> <b>Last Name
                        </b></Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName}
                        />
                      </Form.Group>

                      <Form.Group as={Col} className="mb-3" md="12" controlId="validationCustom04">
                        <Form.Label><b> Address
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter Address"
                          style={{ height: "29px" }}
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                        />
                      </Form.Group>

                      <Form.Group as={Col} className="mb-3" md="6" controlId="validationCustom05">
                        <Form.Label> <b>Country
                          <span>*</span></b></Form.Label>
                        <Form.Select aria-label="Default select example"
                          onChange={(e) => setCountryID(e.target.value)}
                          value={country_id}
                          onClick={(e) =>
                            getStates(e.target[e.target.selectedIndex].title)
                          }
                        >
                          <option>-Select-</option>
                          {countryLists.length > 0
                            ? countryLists.map((item, key) => (
                              <option
                                value={item._id}
                                key={key}
                                title={item.id}
                              >
                                {item.name}
                              </option>
                            ))
                            : ""}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group as={Col} className="mb-3" md="6" controlId="validationCustom05">
                        <Form.Label> <b>State
                          <span>*</span></b></Form.Label>
                        <Form.Select aria-label="Default select example"
                          onChange={(e) => setStateID(e.target.value)}
                          value={state_id}
                          onClick={(e) =>
                            getCities(e.target[e.target.selectedIndex].title)
                          }
                        >
                          <option>-Select-</option>
                          {stateLists.length > 0
                            ? stateLists.map((item, key) => (
                              <option
                                value={item._id}
                                key={key}
                                title={item.id}
                              >
                                {item.name}
                              </option>
                            ))
                            : ""}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group as={Col} className="mb-3" md="6" controlId="validationCustom05">
                        <Form.Label> <b>City
                          <span>*</span></b></Form.Label>
                        <Form.Select aria-label="Default select example"
                          onChange={(e) => setCityID(e.target.value)}
                          value={city_id}
                        >
                          <option>-Select-</option>
                          {cityLists.length > 0
                            ? cityLists.map((item, key) => (
                              <option value={item._id} key={key}>
                                {item.name}
                              </option>
                            ))
                            : ""}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group as={Col} className="mb-3" md="6" controlId="validationCustom05">
                        <Form.Label> <b>Postal Code
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="Enter Postel Code"
                          onChange={(e) => setPostalCode(e.target.value)}
                          value={postalCode}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Row>
                      <Form.Group as={Col} className="mb-3" md="12" controlId="validationCustom05">
                        <Form.Label> <b>Mobile Number
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="Enter Mobile Number"
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" md="12" controlId="validationCustom05">
                        <Form.Label> <b>Office Phone
                        </b></Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Office Phone"
                          onChange={(e) => setOfficePhone(e.target.value)}
                          value={office_phone}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" md="12" controlId="validationCustom05">
                        <Form.Label> <b>Email Id
                          <span>*</span></b></Form.Label>
                        <Form.Control
                          required
                          type="email"
                          disabled
                          placeholder="Enter Email Id"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3 text-end" md="12" controlId="validationCustom05">
                        <Button variant="success" className="w-25"
                          disabled={!token || !firstName || !middleName || !lastName || !address || !phone || !office_phone || !city_id || !state_id || !country_id || !postalCode}
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Container>
          </div>
          :
          status === "registered" ?
            <AlreadyRegisteredMessage />
            :
            status === "cancelled" ?
              <ExpiredInvitationMessage />
              :
              <ExpiredInvitationMessage />
      }
    </>
  );
};
export default UserInvitationFormData;
