import React from "react";

const ExpiredInvitationMessage = () => {
  return (
    <>
      <div className="Form_justification">
        <div className="invite__form_message">
          <p>Expired Invitation Link</p>
          <div className="Success__taag mb-3">
            <i style={{ color: "red" }} class="fa-solid fa-circle-xmark" alt="Invitation Link Expired"></i>
          </div>
          <span>Your Invitation link has been expired.</span>
          <span>Please contact "Administrator".</span>
          <span>After that check your mail, then you are able to Registration</span>
        </div>
      </div>
    </>
  );
};

export default ExpiredInvitationMessage;
